import { DeleteIcon, EditIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import { Button, Image, useToast } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "./Album.css";
import Cookies from "js-cookie"
function Album() {
  const [image1, setimage1] = useState();
  const [isDBimage1, setisDBimage1] = useState(true);
  const [uploadedImage1, setUploadedImage1] = useState(null);
  //get referance to first image uploader
  const hiddenFileInput1 = React.useRef(null);
  // handle upload image 1
  const handleimage1upload = (event) => {
    hiddenFileInput1.current.click();
  };
  //handle change on image 1
  const handleimage1Change = (event) => {
    const fileUploaded = event.target.files[0];
    setUploadedImage1(event.target.files[0]);
    setimage1(URL.createObjectURL(fileUploaded));
    setisDBimage1(false);
  };
  // clear image 1
  const clearimage1 = async (event) => {
    setimage1(null);
    hiddenFileInput1.current.value = null;
    setUploadedImage1(null);
  };

  const [image2, setimage2] = useState();
  const [isDBimage2, setisDBimage2] = useState(true);
  const [uploadedImage2, setUploadedImage2] = useState(null);
  //get referance to first image uploader
  const hiddenFileInput2 = React.useRef(null);
  // handle upload image 2
  const handleimage2upload = (event) => {
    hiddenFileInput2.current.click();
  };
  //handle change on image 2
  const handleimage2Change = (event) => {
    const fileUploaded = event.target.files[0];
    setUploadedImage2(event.target.files[0]);
    setimage2(URL.createObjectURL(fileUploaded));
    setisDBimage2(false);
  };
  // clear image 2
  const clearimage2 = async (event) => {
    setimage2(null);
    hiddenFileInput2.current.value = null;
    setUploadedImage2(null);
  };

  const [image3, setimage3] = useState();
  const [isDBimage3, setisDBimage3] = useState(true);
  const [uploadedImage3, setUploadedImage3] = useState(null);
  //get referance to first image uploader
  const hiddenFileInput3 = React.useRef(null);
  // handle upload image 3
  const handleimage3upload = (event) => {
    hiddenFileInput3.current.click();
  };
  //handle change on image 3
  const handleimage3Change = (event) => {
    const fileUploaded = event.target.files[0];
    setUploadedImage3(event.target.files[0]);
    setimage3(URL.createObjectURL(fileUploaded));
    setisDBimage3(false);
  };
  // clear image 3
  const clearimage3 = async (event) => {
    setimage3(null);
    hiddenFileInput3.current.value = null;
    setUploadedImage3(null);
  };

  const [image4, setimage4] = useState();
  const [isDBimage4, setisDBimage4] = useState(true);
  const [uploadedImage4, setUploadedImage4] = useState(null);
  //get referance to first image uploader
  const hiddenFileInput4 = React.useRef(null);
  // handle upload image 4
  const handleimage4upload = (event) => {
    hiddenFileInput4.current.click();
  };
  //handle change on image 4
  const handleimage4Change = (event) => {
    const fileUploaded = event.target.files[0];
    setUploadedImage4(event.target.files[0]);
    setimage4(URL.createObjectURL(fileUploaded));
    setisDBimage4(false);
  };
  // clear image 4
  const clearimage4 = async (event) => {
    setimage4(null);
    hiddenFileInput4.current.value = null;
    setUploadedImage4(null);
  };

  const [image5, setimage5] = useState();
  const [isDBimage5, setisDBimage5] = useState(true);
  const [uploadedImage5, setUploadedImage5] = useState(null);
  //get referance to first image uploader
  const hiddenFileInput5 = React.useRef(null);
  // handle upload image 5
  const handleimage5upload = (event) => {
    hiddenFileInput5.current.click();
  };
  //handle change on image 5
  const handleimage5Change = (event) => {
    const fileUploaded = event.target.files[0];
    setUploadedImage5(event.target.files[0]);
    setimage5(URL.createObjectURL(fileUploaded));
    setisDBimage5(false);
  };
  // clear image 5
  const clearimage5 = async (event) => {
    setimage5(null);
    hiddenFileInput5.current.value = null;
    setUploadedImage5(null);
  };

  const [refreshKey, setRefreshKey] = useState(0);
  const toast = useToast();

  const [image1Flag,setimage1Flag]=useState(false)
  const [image2Flag,setimage2Flag]=useState(false)
  const [image3Flag,setimage3Flag]=useState(false)
  const [image4Flag,setimage4Flag]=useState(false)
  const [image5Flag,setimage5Flag]=useState(false)
  // Get the saloon general infos
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/album", {
          headers: {
            'Content-Type': 'application/json',
            "Authorization": Cookies.get("authenticationToken")
          }
        });
        setimage1(res.data[0].image1);
        setimage2(res.data[0].image2);
        setimage3(res.data[0].image3);
        setimage4(res.data[0].image4);
        setimage5(res.data[0].image5);
      } catch (err) {
        if (err.response.status === 400) {
          toast({
            title: "Bad Request",
            description: "Missing token authorization",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 401) {
          toast({
            title: "Unauthorized Request",
            description: "Expired or Incorrect Token",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 500) {
          toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  // Update Saloon Infos
  const handleUpdateAlbum = async (e) => {
    e.preventDefault();
    try {
      const saloonData = new FormData();
      saloonData.append("image1", uploadedImage1);
      saloonData.append("image2", uploadedImage2);
      saloonData.append("image3", uploadedImage3);
      saloonData.append("image4", uploadedImage4);
      saloonData.append("image5", uploadedImage5);
      
      saloonData.append("image1link",image1Flag)
      saloonData.append("image2link",image2Flag)
      saloonData.append("image3link",image3Flag)
      saloonData.append("image4link",image4Flag)
      saloonData.append("image5link",image5Flag)
      const res = await axios.put(`/album`, saloonData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          "Authorization": Cookies.get("authenticationToken")
        }
      });
      if (res.status === 200) {
        setRefreshKey((oldKey) => oldKey + 1);
        setisDBimage1(true);
        setisDBimage2(true);
        setisDBimage3(true);
        setisDBimage5(true);
        setisDBimage4(true);
        setimage1Flag(false)
        setimage2Flag(false)
        setimage3Flag(false)
        setimage4Flag(false)
        setimage5Flag(false)
        toast({
          title: "Virksomheds album opdateret",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (err) {
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  return (
    <>
      <div className="Album">
        <div className="Albumheader">
          <InfoOutlineIcon /> Upload maksimalt 5 billeder - 5 MB maksimal
          størrelse
        </div>
        <div className="Galleriecontainer">
          <div className="Galleryitem">
            <div className="Image">
              <Image
                h={180}
                w={380}
                src={
                  !isDBimage1
                    ? image1
                    : "https://park-concept-api.bookinghero.io" + image1 //"https://park-concept-api.bookinghero.io"
                }
                fallbackSrc="/assets/placeHolder/saloon.jpeg"
                borderRadius="10px"
              />
            </div>
            <div className="Buttonscontainer">
              <div>
                <input
                  type="file"
                  ref={hiddenFileInput1}
                  onChange={handleimage1Change}
                  style={{ display: "none" }}
                />
                <Button
                  leftIcon={<EditIcon />}
                  colorScheme="teal"
                  variant="ghost"
                  onClick={() => {
                    hiddenFileInput1.current.value = null;
                    handleimage1upload();
                    setimage1Flag(true)
                  }}

                >
                  Upload
                </Button>
              </div>
              <div>
                <Button
                  leftIcon={<DeleteIcon />}
                  colorScheme="red"
                  variant="ghost"
                  onClick={()=>{clearimage1();setimage1Flag(true)}}
                >
                  Slet
                </Button>
              </div>
            </div>
          </div>
          <div className="Galleryitem">
            <div className="Image">
              <Image
                h={180}
                w={380}
                src={
                  !isDBimage2
                    ? image2
                    : "https://park-concept-api.bookinghero.io" + image2
                }
                fallbackSrc="/assets/placeHolder/saloon.jpeg"
                borderRadius="10px"
              />
            </div>
            <div className="Buttonscontainer">
              <div>
                <input
                  type="file"
                  ref={hiddenFileInput2}
                  onChange={handleimage2Change}
                  style={{ display: "none" }}
                />
                <Button
                  leftIcon={<EditIcon />}
                  colorScheme="teal"
                  variant="ghost"
                  onClick={() => {
                    hiddenFileInput2.current.value = null;
                    handleimage2upload();
                    setimage2Flag(true)
                  }}
                >
                  Upload
                </Button>
              </div>
              <div>
                <Button
                  leftIcon={<DeleteIcon />}
                  colorScheme="red"
                  variant="ghost"
                  onClick={()=>{clearimage2();setimage2Flag(true)}}
                >
                  Slet
                </Button>
              </div>
            </div>
          </div>
          <div className="Galleryitem">
            <div className="Image">
              <Image
                h={180}
                w={380}
                src={
                  !isDBimage3
                    ? image3
                    : "https://park-concept-api.bookinghero.io" + image3
                }
                fallbackSrc="/assets/placeHolder/saloon.jpeg"
                borderRadius="10px"
              />
            </div>
            <div className="Buttonscontainer">
              <div>
                <input
                  type="file"
                  ref={hiddenFileInput3}
                  onChange={handleimage3Change}
                  style={{ display: "none" }}
                />
                <Button
                  leftIcon={<EditIcon />}
                  colorScheme="teal"
                  variant="ghost"
                  onClick={() => {
                    hiddenFileInput3.current.value = null;
                    handleimage3upload();
                    setimage3Flag(true)
                  }}
                >
                  Upload
                </Button>
              </div>
              <div>
                <Button
                  leftIcon={<DeleteIcon />}
                  colorScheme="red"
                  variant="ghost"
                  onClick={()=>{clearimage3();setimage3Flag(true)}}
                >
                  Slet
                </Button>
              </div>
            </div>
          </div>
          <div className="Galleryitem">
            <div className="Image">
              <Image
                h={180}
                w={380}
                src={
                  !isDBimage4
                    ? image4
                    : "https://park-concept-api.bookinghero.io" + image4
                }
                fallbackSrc="/assets/placeHolder/saloon.jpeg"
                borderRadius="10px"
              />
            </div>
            <div className="Buttonscontainer">
              <div>
                <input
                  type="file"
                  ref={hiddenFileInput4}
                  onChange={handleimage4Change}
                  style={{ display: "none" }}
                />
                <Button
                  leftIcon={<EditIcon />}
                  colorScheme="teal"
                  variant="ghost"
                  onClick={() => {
                    hiddenFileInput4.current.value = null;
                    handleimage4upload();
                    setimage4Flag(true)
                  }}
                >
                  Upload
                </Button>
              </div>
              <div>
                <Button
                  leftIcon={<DeleteIcon />}
                  colorScheme="red"
                  variant="ghost"
                  onClick={()=>{clearimage4();setimage4Flag(true)}}
                >
                  Slet
                </Button>
              </div>
            </div>
          </div>
          <div className="Galleryitem">
            <div className="Image">
              <Image
                h={180}
                w={380}
                src={
                  !isDBimage5
                    ? image5
                    : "https://park-concept-api.bookinghero.io" + image5
                }
                fallbackSrc="/assets/placeHolder/saloon.jpeg"
                borderRadius="10px"
              />
            </div>
            <div className="Buttonscontainer">
              <div>
                <input
                  type="file"
                  ref={hiddenFileInput5}
                  onChange={handleimage5Change}
                  style={{ display: "none" }}
                />
                <Button
                  leftIcon={<EditIcon />}
                  colorScheme="teal"
                  variant="ghost"
                  onClick={() => {
                    hiddenFileInput5.current.value = null;
                    handleimage5upload();
                    setimage5Flag(true)
                  }}
                >
                  Upload
                </Button>
              </div>
              <div>
                <Button
                  leftIcon={<DeleteIcon />}
                  colorScheme="red"
                  variant="ghost"
                  onClick={()=>{clearimage5();setimage5Flag(true)}}
                >
                  Slet
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Button className="Updatebtn" onClick={handleUpdateAlbum}>
          Opdatering
        </Button>
      </div>
    </>
  );
}

export default Album;
