import React from "react";
import { Link } from "react-router-dom";
import "./Businesssidebar.css";
import { Icon } from "@chakra-ui/icons";
import {
  MdSpaceDashboard,
  MdCalendarToday,
  MdEditCalendar,
  MdMedicalServices,
  MdPersonAddAlt1,
  MdOutlineStarHalf,
  MdCreditCard,
  MdUpgrade,
  MdSettings,
  MdOutlineLogout,
  MdQueryStats,
} from "react-icons/md";
import { Text, useToast } from "@chakra-ui/react";
import Cookies from "js-cookie"
import axios from "axios";
function Businesssidebar() {



  // declare toast for notifications
  const toast = useToast();

  // handle removing cookies
  function removeCookiePromise(key) {
    return new Promise((resolve, reject) => {
      try {
        Cookies.remove(key);
        resolve("Cookie removed successfully");
      } catch (error) {
        reject(error);
      }
    });
  }

  // handle logging out of dashboard
  const logout = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.get("/saloon/logout", {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": Cookies.get("authenticationToken")
        }
      });
      if (res.status === 204) {
        removeCookiePromise("authenticationToken")
          .then(() => {
            window.location.href = "/log-ind";
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
      else if (res.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      else if (res.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Incorrect Or Expired token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
        removeCookiePromise("authenticationToken")
          .then(() => {
            window.location.href = "/log-ind";
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    } catch (err) {
      toast({
        title: "Server Fejl",
        description: "prøv venligst igen om nogle minutter",
        position: "top-right",
        isClosable: true,
        status: "error",
      });
    }
  };

  return (
    <>
      <div className="Businesssidebar">
        <div>
          <img
            src={process.env.PUBLIC_URL + "/assets/Logo/green.png"}
            alt=""
            className="greencontainerTop"
          ></img>
        </div>
        <div className="Nav">
          <Link to="/bookingsystem/kontrolpanel" className="Navitem">
            <Icon as={MdSpaceDashboard} className="Navicon" w={5} h={5} />{" "}
            Kontrolpanel
          </Link>
          <Link to="/bookingsystem/kalender" className="Navitem">
            <Icon as={MdCalendarToday} className="Navicon" w={5} h={5} />{" "}
            Kalender
          </Link>
          <Link to="/bookingsystem/aftaler" className="Navitem">
            <Icon as={MdEditCalendar} className="Navicon" w={5} h={5} /> Aftaler
          </Link>
          <Link to="/bookingsystem/tjenester" className="Navitem">
            <Icon as={MdMedicalServices} className="Navicon" w={5} h={5} />{" "}
            Tjenester
          </Link>
          <Link to="/bookingsystem/personale" className="Navitem">
            <Icon as={MdPersonAddAlt1} className="Navicon" w={5} h={5} />
            Personale
          </Link>
          <Link to="/bookingsystem/anmeldelser" className="Navitem">
            <Icon as={MdOutlineStarHalf} className="Navicon" w={5} h={5} />{" "}
            Anmeldelser
          </Link>
          <Link to="/bookingsystem/statistik" className="Navitem">
            <Icon as={MdQueryStats} className="Navicon" w={5} h={5} /> Statistik
          </Link>
          <Link to="/bookingsystem/betaling" className="Navitem isDisabled">
            <Icon as={MdCreditCard} className="Navicon" w={5} h={5} /> Betaling{" "}
            <span className="soon_tag">snart</span>
          </Link>
          <Link to="/bookingsystem/opgradere" className="Navitem isDisabled">
            <Icon as={MdUpgrade} className="Navicon" w={5} h={5} /> Opgrader{" "}
            <span className="soon_tag">snart</span>
          </Link>
          <Link
            to="/bookingsystem/indstillinger/sec/generelinformation"
            className="Navitem"
          >
            <Icon as={MdSettings} className="Navicon" w={5} h={5} />{" "}
            Indstillinger
          </Link>
          <span className="Navitem" id="Logout" onClick={logout}>
            <Icon as={MdOutlineLogout} className="Navicon" w={5} h={5} /> Log ud
          </span>

        </div>

        <div className="bottomSidebar">


          <Link to="#">
            <img
              src={process.env.PUBLIC_URL + "/assets/Logo/A_logo.png"}
              alt=""
              className="Logocontainer"
            ></img>
            <Text width={"fit-content"} margin={"auto"} marginBottom={0} marginTop={1} fontWeight={"bold"} fontSize={"18px"}>By BUCH</Text>
          </Link>
          <img
            src={process.env.PUBLIC_URL + "/assets/Logo/green.png"}
            alt=""
            className="greenContainerBottom"
          ></img>
        </div>
      </div>
    </>
  );
}

export default Businesssidebar;
