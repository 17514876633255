import React, { useEffect, useRef, useState } from "react";
import "./Generalinfo.css";
import {
  Button,
  Image,
  Input,
  Select,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import Cookies from "js-cookie"
function Generalinfo() {
  // variables and functions related to saloon logo
  // **********************************************
  const [uploadedImage, setUploadedImage] = useState(null);
  const [image1, setimage1] = useState(null);
  //get referance to first image uploader
  const hiddenFileInput1 = React.useRef(null);
  // handle upload image 1
  const handleimage1upload = (event) => {
    hiddenFileInput1.current.click();
  };
  //handle change on image 1
  const handleimage1Change = (event) => {
    const fileUploaded = event.target.files[0];
    setUploadedImage(event.target.files[0]);
    setimage1(URL.createObjectURL(fileUploaded));
  };
  // clear image 1
  const clearimage1 = async (event) => {
    setimage1(null);
    hiddenFileInput1.current.value = null;
    setUploadedImage(null);
  };
  // **********************************************

  const [refreshKey, setRefreshKey] = useState(0);
  const toast = useToast();

  // References to inputs
  const saloonNameRef = useRef();
  const saloonCityRef = useRef();
  const saloonAdrRef = useRef();
  const saloonAboutRef = useRef();
  const saloonCategoryRef = useRef();
  const [categories, set_categories] = useState([]);
  const [selected_category, set_selected_category] = useState("");

  // keep track of the saloon object
  const [saloon, setSaloon] = useState({});

  // Get the saloon general infos
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/saloon/general", {
          headers: {
            'Content-Type': 'application/json',
            "Authorization": Cookies.get("authenticationToken")
          }
        });
        setSaloon(res.data.general_settings[0]);
        setimage1(
          "https://park-concept-api.bookinghero.io" + res.data.general_settings[0].logo
        );
        set_categories(res.data.categories);
        set_selected_category(res.data.general_settings[0].category);
      } catch (err) {
        if (err.response.status === 400) {
          toast({
            title: "Bad Request",
            description: "Missing token authorization",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 401) {
          toast({
            title: "Unauthorized Request",
            description: "Expired or Incorrect Token",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 500) {
          toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  // Update Saloon Infos
  const handleUpdateSaloon = async (e) => {
    e.preventDefault();
    try {
      let res = 0;
      if (uploadedImage !== null) {
        const saloonData = new FormData();
        saloonData.append("name", saloonNameRef.current.value);
        saloonData.append("city", saloonCityRef.current.value);
        saloonData.append("adress", saloonAdrRef.current.value);
        saloonData.append("about", saloonAboutRef.current.value);
        saloonData.append("category", selected_category);
        saloonData.append("logo", uploadedImage);
        res = await axios.put(`/saloon/general`, saloonData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            "Authorization": Cookies.get("authenticationToken")
          }
        });
        
        if (res.status === 200) {
          setRefreshKey((oldKey) => oldKey + 1);
          toast({
            title: "Virksomheds Info opdateret",
            status: "success",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
        }
      } else {
        const saloonData2 = {
          name: saloonNameRef.current.value,
          city: saloonCityRef.current.value,
          adress: saloonAdrRef.current.value,
          about: saloonAboutRef.current.value,
          category: selected_category,
        };
        res = await axios.put(`/saloon/general/without-logo`, saloonData2, {
          headers: {
            'Content-Type': 'application/json',
            "Authorization": Cookies.get("authenticationToken")
          }
        });
        if (res.status === 200) {
          setRefreshKey((oldKey) => oldKey + 1);
          toast({
            title: "Virksomheds Info opdateret",
            status: "success",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
        }
      }
    } catch (err) {
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };
  function handle_category_change(event) {
    set_selected_category(event.target.value);
  }

  // Front end starts here
  return (
    <>
      <div className="Generalinfo">
        <div className="title">Generel information</div>
        <div className="TopMan">
          <div>
            <Image
              fallbackSrc="/assets/placeHolder/saloon.jpeg"
              alt=""
              className="Image"
              boxSize="100px"
              objectFit="cover"
              src={image1}
              type="file"
            />
            <input
              type="file"
              ref={hiddenFileInput1}
              onChange={handleimage1Change}
              style={{ display: "none" }}
            />
          </div>
          <Button
            className="uploadBtn"
            onClick={() => {
              hiddenFileInput1.current.value = null;
              handleimage1upload();
            }}
          >
            Upload nyt logo
          </Button>
          <Button className="deleteBtn" onClick={clearimage1}>
            Slet
          </Button>
        </div>
        <div className="Label">Virksomheds navn</div>
        <Input
          variant="filled"
          required
          ref={saloonNameRef}
          defaultValue={saloon.name}
          className="SalonNameInput"
        />
        <div className="doubleInputs">
          <div className="addressInput">
            <div className="LabelDoubleInput">Adresse</div>
            <Input
              variant="filled"
              ref={saloonAdrRef}
              defaultValue={saloon.adress}
              required
            />
          </div>
          <div className="cityInput">
            <div className="LabelDoubleInput">By</div>
            <Input
              variant="filled"
              ref={saloonCityRef}
              defaultValue={saloon.city}
              required
            />
          </div>
        </div>
        <div className="Label">Om virksomheden</div>
        <Textarea
          variant="filled"
          ref={saloonAboutRef}
          defaultValue={saloon.about}
          required
          className="SalonNameInput"
        />
        <div className="Label">Kategorier</div>
        <div className="SalonNameInput">
          <Select
            variant="filled"
            value={selected_category}
            onChange={handle_category_change}
          >
            {categories.map((category) => (
              <option key={category.name} value={category.name}>
                {category.name}
              </option>
            ))}
          </Select>
        </div>
        <Button className="Submitbtn" onClick={handleUpdateSaloon}>
          Opdatering
        </Button>
      </div>
    </>
  );
}

export default Generalinfo;
