import { InfoOutlineIcon, RepeatIcon } from "@chakra-ui/icons";
import {
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverHeader,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Appointment.css";
import Cookies from "js-cookie";
function Appointment() {
  const [refreshKey, setRefreshKey] = useState(0);
  const toast = useToast();

  const [appointments, setAppointments] = useState([]);

  // Get the saloon appointement
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/appointment", {
          headers: {
            "Content-Type": "application/json",
            "Authorization": Cookies.get("authenticationToken")
          },
        });
      setAppointments(res.data);
      } catch (err) {
        if (err.response.status === 400) {
          toast({
            title: "Bad Request",
            description: "Missing token authorization",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 401) {
          toast({
            title: "Unauthorized Request",
            description: "Expired or Incorrect Token",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 500) {
          toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  // Accept appointment
  const handleAcceptAppointment = async (e, appointmentId) => {
    e.preventDefault();
 
    try {
      const res = await axios.put(`/appointment/accept/${appointmentId}`,{}, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": Cookies.get("authenticationToken")
        }
      });
      if (res.status === 200) {
        
        setRefreshKey((oldKey) => oldKey + 1);
        toast({
          title: "Aftale Accepteret",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (err) {
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };
  // Decline appointment
  const handleDeclineAppointment = async (e, appointmentId) => {
    e.preventDefault();
    try {
      const res = await axios.put(`/appointment/decline/${appointmentId}`,{}, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": Cookies.get("authenticationToken"),
        },
      });
      if (res.status === 200) {
        setRefreshKey((oldKey) => oldKey + 1);
        toast({
          title: "Aftale Afslået",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
    } catch (err) {
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  return (
    <>
      <div className="Appointments">
        <div className="Header">Administrer dine aftaler</div>
        <div className="Subheadercontainer">
          <div className="Subheader">Afventende aftaler</div>
          <div className="Refreshbtncontainer">
            <Button
              rightIcon={<RepeatIcon />}
              variant="solid"
              className="Refreshbtn"
              onClick={() => setRefreshKey(refreshKey + 1)}
            >
              Opdater 
            </Button>
          </div>
        </div>
        {appointments.length > 0 ? (
          <>
            <div className="Tablecontainer">
              <TableContainer className="Table">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Kunde</Th>
                      <Th>Dato</Th>
                      <Th>Tid</Th>
                      <Th>Service</Th>
                      <Th>Pris</Th>
                      <Th>Personale</Th>
                      <Th>Noter</Th>
                      <Th>Status</Th>
                      <Th>Handlinger</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {appointments &&
                      appointments.map((appointment) => (
                        <Tr key={appointment.id}>
                          <Td>{appointment.clientname}</Td>
                          <Td>{appointment.appointmentDate.split("T")[0]}</Td>
                          <Td>
                          {appointment.appointmentStart.split(":").slice(0, 2).join(":")} -{" "}
                          {appointment.appointmentEnd.split(":").slice(0, 2).join(":")}
                          </Td>
                          <Td className="Td">{appointment.name}</Td>
                          <Td>{appointment.price} kr</Td>
                          <Td>{appointment.staffname}</Td>
                          <Td className="Notes">
                            {appointment.comment ? (
                              <>
                                <Popover>
                                  <PopoverTrigger>
                                    <InfoOutlineIcon className="Noteicon" />
                                  </PopoverTrigger>
                                  <PopoverContent
                                    // color="white"
                                    // bg="#fe6b28"
                                    // borderColor="#fe6b28"
                                  >
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverHeader>Noter :</PopoverHeader>
                                    <div className="Popovertext">
                                      <PopoverBody alignItems="center">
                                        {appointment.comment}
                                      </PopoverBody>
                                    </div>
                                  </PopoverContent>
                                </Popover>
                              </>
                            ) : (
                              <></>
                            )}
                          </Td>
                          <Td>Igangværende</Td>
                          <Td>
                            <Link
                              className="Acceptlink"
                              onClick={(event) =>
                                handleAcceptAppointment(event, appointment.appointment_id)
                              }
                            >
                              Acceptere
                            </Link>
                            <Link
                              className="Declinelink"
                              onClick={(event) =>
                                handleDeclineAppointment(event, appointment.appointment_id)
                              }
                            >
                              Afvise
                            </Link>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </div>
          </>
        ) : (
          <>
            <div className="Emptylistcontainer">
              <InfoOutlineIcon className="Emptyicon" />
              <div className="Emptyheader">Ingen aftaler endnu !</div>
              <div className="Emptytext">
                Når du tilføjer aftaler, vises de her
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Appointment;
