import React from "react";
import Businesssidebar from "../components/Businesssidebar";
import Dashboard from "../components/Dashboard";
import Appointments from "../components/Appointment";
import "./Business.css";
import { Route, Routes } from "react-router-dom";
import BusinesssidebarMobile from "../components/BusinesssidebarMobile";
import Services from "../components/Services";
import Staffs from "../components/Staffs";
import Reviews from "../components/Reviews";
import Settings from "../components/Settings";
import Payment from "../components/Payment";
import CalendarPage from "../components/CalendarPage";
import Upgrade from "../components/Upgrade";
import Statistic from "../components/Statistic";

function Business() {
  return (
    <>
      <div className="Business">
        <div className="BusinesssidebarMobile">
          <BusinesssidebarMobile className="Mobilesidebar" />
        </div>
        <div className="Businesssidebar">
          <Businesssidebar />
        </div>
        <div className="Businessright">
          <Routes>
            <Route path="kontrolpanel" element={<Dashboard />} />
            <Route path="aftaler" element={<Appointments />} />
            <Route path="tjenester" element={<Services />} />
            <Route path="personale" element={<Staffs />} />
            <Route path="anmeldelser" element={<Reviews />} />
            <Route path="betaling" element={<Payment />} />
            <Route exact path="indstillinger/sec/*" element={<Settings />} />
            <Route path="kalender" element={<CalendarPage />} />
            <Route path="opgradere" element={<Upgrade />} />
            <Route path="statistik" element={<Statistic />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default Business;
