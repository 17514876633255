import { ChevronDownIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Spinner,
  Stat,
  StatArrow,
  StatHelpText,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { Chart } from "react-google-charts";
import moment from "moment/moment";
import axios from "axios";
import Cookies from "js-cookie"
// Bar chart options and information
export const options = {
  chartArea: {
    height: "100%",
    width: "100%",
    top: 38,
    left: 48,
    right: 180,
    bottom: 90,
  },
  height: "100%",
  width: "100%",
  colors: ["black"],
  hAxis: {
    slantedText: true,
    slantedTextAngle: 90,
  },
};

function Dashboard() {
  // check if loading animation
  const [barchartloading, setbarchartloading] = useState(false);
  //****************Asign values to dashboard*****************/
  const [numberbookings, setnumberbookings] = useState(0);
  const [number_bookings_percentage, set_number_bookings_percentage] =
    useState(0);
  const [numberaccepted, setnumberaccepted] = useState(0);
  const [numberdeclined, setnumberdeclined] = useState(0);
  const [numberpending, setnumberpending] = useState(0);
  const [earning, setearning] = useState(0);
  const [earning_percentage, set_earning_percentage] = useState(0);
  const [menue_indicator, set_menue_indicator] = useState("Sidste 7 dage");
  const [last_week_callback, set_last_week_callback] = useState(0);
  const [Piedata, setPiedata] = useState([
    ["Services", "Number of bookings"],
    ["Service 1", 11],
    ["Service 2", 2],
    ["Service 3", 2],
    ["Service 4", 2],
    ["Service 5", 7],
  ]);
  const [Bardata, setBardata] = useState([]);
  //****************Asign values to dashboard*****************/
  const toast = useToast();
  // get the data of the dashboard on page load - Last 7 days
  useEffect(() => {
    const fetchData = async () => {
      setbarchartloading(true);
      try {
        const res = await axios.get("/dashboard", {
          headers: {
            'Content-Type': 'application/json',
            "Authorization":Cookies.get("authenticationToken")
          }
        });
        if (res.status === 200) {
          /****setting data for the cards****/
          set_menue_indicator("Sidste 7 dage");
          setnumberbookings(res.data.numberbookings);
          setnumberaccepted(res.data.numberaccepted);
          setnumberdeclined(res.data.numberdeclined);
          setnumberpending(res.data.numberpending);
          setearning(res.data.earning);
          set_number_bookings_percentage(res.data.number_bookings_percentage);
          set_earning_percentage(res.data.earning_percentage);
          /****setting data for the cards****/

          /****setting the piedata chart****/
          let piechart = [];
          piechart.push(["Services", "Number of bookings"]);
          for (let index = 0; index < res.data.services.length; index++) {
            piechart.push([
              res.data.services[index].name,
              res.data.services[index].bookings,
            ]);
          }
          setPiedata(piechart);
          /****setting the piedata chart****/

          /****setting the bardata chart****/
          // get the past 7 days
          let baredata = [];
          baredata.push(["Dag", "Reservationer"]);
          for (let i = 6; i >= 0; i--) {
            const today = moment();
            const day = today.subtract(i, "days");
            baredata.push([day.format("dddd"), res.data.days[i]]);
          }
          setbarchartloading(false);
          setBardata(baredata);

          /****setting the bardata chart****/
        }
      } catch (err) {
        if (err.response.status === 400) {
          toast({
            title: "Bad Request",
            description: "Missing token authorization",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 401) {
          toast({
            title: "Unauthorized Request",
            description: "Expired or Incorrect Token",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
        if (err.response.status === 500) {
          toast({
            title: "Server Fejl",
            description: "Prøv venligst igen om et øjeblik",
            position: "top-right",
            isClosable: true,
            status: "error",
          });
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [last_week_callback]);
  // Get data for dashboard - Last 30 days
  const last_month = async () => {
    setbarchartloading(true);
    try {
      const res = await axios.get("/dashboard/month", {
        headers: {
          'Content-Type': 'application/json',
          "Authorization":Cookies.get("authenticationToken")
        }
      });
      if (res.status === 200) {
        /****setting data for the cards****/
        setnumberbookings(res.data.numberbookings);
        setnumberaccepted(res.data.numberaccepted);
        setnumberdeclined(res.data.numberdeclined);
        setnumberpending(res.data.numberpending);
        setearning(res.data.earning);
        set_number_bookings_percentage(res.data.number_bookings_percentage);
        set_earning_percentage(res.data.earning_percentage);
        set_menue_indicator("Sidste 30 dage");
        /****setting data for the cards****/
        /****setting the piedata chart****/
        let piechart = [];
        piechart.push(["Services", "Number of bookings"]);
        for (let index = 0; index < res.data.services.length; index++) {
          piechart.push([
            res.data.services[index].name,
            res.data.services[index].bookings,
          ]);
        }
        setPiedata(piechart);
        /****setting the piedata chart****/
        /****setting the bardata chart****/
        // get the past 7 days
        let baredata = [];
        baredata.push(["Dag", "Reservationer"]);
        for (let i = 30; i >= 0; i--) {
          const today = moment();
          const day = today.subtract(i, "days");
          baredata.push([
            day.format("dddd") + " - " + day.format("D"),
            res.data.days[i],
          ]);
        }
        setbarchartloading(false);
        setBardata(baredata);
        /****setting the bardata chart****/
      }
    } catch (err) {
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    
    }
  };
  // Get data for dashboard - Last 12 months
  const last_year = async () => {
    setbarchartloading(true);
    try {
      const res = await axios.get("/dashboard/year", {
        headers: {
          'Content-Type': 'application/json',
          "Authorization":Cookies.get("authenticationToken")
        }
      });
      if (res.status === 200) {
        /****setting data for the cards****/
        setnumberbookings(res.data.numberbookings);
        setnumberaccepted(res.data.numberaccepted);
        setnumberdeclined(res.data.numberdeclined);
        setnumberpending(res.data.numberpending);
        setearning(res.data.earning);
        set_number_bookings_percentage(res.data.number_bookings_percentage);
        set_earning_percentage(res.data.earning_percentage);
        set_menue_indicator("Sidste 12 måneder");
        /****setting data for the cards****/
        /****setting the piedata chart****/
        let piechart = [];
        piechart.push(["Services", "Number of bookings"]);
        for (let index = 0; index < res.data.services.length; index++) {
          piechart.push([
            res.data.services[index].name,
            res.data.services[index].bookings,
          ]);
        }
        setPiedata(piechart);
        /****setting the piedata chart****/
        /****setting the bardata chart****/
        // get the past 7 days
        let baredata = [];
        baredata.push(["Måneder", "Reservationer"]);
        for (let i = 11; i >= 0; i--) {
          const today = moment();
          const day = today.subtract(i, "months");
          baredata.push([day.format("MMMM"), res.data.days[i]]);
        }
        setbarchartloading(false);
        setBardata(baredata);
        /****setting the bardata chart****/
      }
    } catch (err) {
      if (err.response.status === 400) {
        toast({
          title: "Bad Request",
          description: "Missing token authorization",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 401) {
        toast({
          title: "Unauthorized Request",
          description: "Expired or Incorrect Token",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
      if (err.response.status === 500) {
        toast({
          title: "Server Fejl",
          description: "Prøv venligst igen om et øjeblik",
          position: "top-right",
          isClosable: true,
          status: "error",
        });
      }
    }
  };

  return (
    <>
      <div className="Dashboard">
        <div className="Dashboardheader">Kontrolpanel</div>
        <div className="Dashboardsubheader">
          Her er et overblik over, hvordan din virksomhed har klaret sig
        </div>
        <div className="Menucontainer">
          <Menu className="Menu">
            <MenuButton
              as={Button}
              rightIcon={<ChevronDownIcon />}
              className="Menubtn"
            >
              Vælg tidsperiode
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  set_last_week_callback(last_week_callback + 1);
                }}
              >
                Sidste 7 dage
              </MenuItem>
              <MenuItem onClick={last_month}>Sidste 30 dage</MenuItem>
              <MenuItem onClick={last_year}>Sidste 12 måneder</MenuItem>
            </MenuList>
          </Menu>
          <div className="Menuindicator">{menue_indicator}</div>
        </div>
        <div className="Cardscontainer">
          <div className="Bookingscard">
            <div className="Bookingscardheader">Reservationer</div>
            <div className="Numberbookings">
              <div className="Number">{numberbookings}</div>
              <div className="Stat">
                <Stat>
                  <StatHelpText className="stattext">
                    {number_bookings_percentage ? (
                      <>
                        {number_bookings_percentage > 0 ? (
                          <>
                            <StatArrow type="increase" className="statarrow" />
                            {number_bookings_percentage}%
                          </>
                        ) : (
                          <>
                            <StatArrow type="decrease" className="statarrow" />
                            <span style={{ color: "red" }}>
                              {number_bookings_percentage}%
                            </span>
                          </>
                        )}
                      </>
                    ) : (
                      <>Ikke tilgængelig</>
                    )}
                  </StatHelpText>
                </Stat>
              </div>
            </div>
            <div className="popover">
              <Popover placement="right">
                <PopoverTrigger>
                  <QuestionOutlineIcon />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>Information</PopoverHeader>
                  <PopoverBody>
                    Antallet af alle aftaler (afventer - accepteret -afvist)
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </div>
          </div>
          <div className="Bookingscard">
            <div className="Bookingscardheader">Indtjening</div>
            <div className="Numberbookings">
              <div className="Number">{earning ? earning : "0"} kr</div>
              <div className="Stat">
                <Stat>
                  <StatHelpText className="stattext">
                    {earning_percentage !== null ? (
                      <>
                        {earning_percentage > 0 ? (
                          <>
                            <StatArrow type="increase" className="statarrow" />
                            {earning_percentage}%
                          </>
                        ) : (
                          <>
                            <StatArrow type="decrease" className="statarrow" />
                            <span style={{ color: "red" }}>
                              {earning_percentage}%
                            </span>
                          </>
                        )}
                      </>
                    ) : (
                      <>Ikke tilgængelig</>
                    )}
                  </StatHelpText>
                </Stat>
              </div>
            </div>
            <div className="popover">
              <Popover placement="right">
                <PopoverTrigger>
                  <QuestionOutlineIcon />
                </PopoverTrigger>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>Information</PopoverHeader>
                  <PopoverBody>
                    Det samlede beløb for alle (accepterede) aftaler
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </div>
          </div>
          <div className="Bookingscard">
            <div className="Bookingscardheader">Aftaler</div>
            <div className="Numberbookings">
              <div className="Starscontainer">
                <div className="Progresscontainer">
                  <div className="progresstext">Accepteret</div>
                  <progress
                    className="Progress"
                    value={(numberaccepted / numberbookings) * 100}
                    max="100"
                  />
                  <div className="Progressnumber">{numberaccepted}</div>
                </div>
                <div className="Progresscontainer">
                  <div className="progresstext">Afvise</div>
                  <progress
                    className="Progress"
                    value={(numberdeclined / numberbookings) * 100}
                    max="100"
                  />
                  <div className="Progressnumber">{numberdeclined}</div>
                </div>
                <div className="Progresscontainer">
                  <div className="progresstext">Igangværende</div>
                  <progress
                    className="Progress"
                    value={(numberpending / numberbookings) * 100}
                    max="100"
                  />
                  <div className="Progressnumber">{numberpending}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Chartcontainer">
          <div className="Chartheader">Statistik om dine reservationer</div>
          <div className="ChartSubHeader">
            Antal bookinger i den valgte tidsperiode
          </div>

          <div className="Chart">
            {barchartloading ? (
              <>
                <Spinner color="red.500" />
              </>
            ) : (
              <>
                <Chart
                  chartType="ColumnChart"
                  width="100%"
                  height="400px"
                  data={Bardata}
                  options={options}
                />
              </>
            )}
          </div>
        </div>
        <div className="BarChartcontainer">
          <div className="Chartheader">Statistik om dine reservationer</div>
          <div className="Chart">
            <Chart
              chartType="BarChart"
              width="100%"
              height="400px"
              data={Bardata}
              options={options}
            />
          </div>
        </div>
        {/* <div className="Piecontainer">
          <div className="Pieheader">Track ​​dine tjenester</div>
          <div className="Chart">
            {Piedata.length <= 1 ? (
              <>
                Ingen tilgængelige data, diagrammet vises når du har nyt
                bookinger
              </>
            ) : (
              <>
                <Chart
                  chartType="PieChart"
                  data={Piedata}
                  width={"100%"}
                  height={"400px"}
                />
              </>
            )}
          </div>
        </div> */}
      </div>
    </>
  );
}

export default Dashboard;
