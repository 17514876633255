import React, { useContext } from "react";
import "./BusinesssidebarMobile.css";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  IconButton,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionPanel,
  AccordionIcon,
  useToast,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Link, useNavigate } from "react-router-dom";
import {
  MdSpaceDashboard,
  MdCalendarToday,
  MdEditCalendar,
  MdMedicalServices,
  MdPersonAddAlt1,
  MdOutlineStarHalf,
  MdCreditCard,
  MdUpgrade,
  MdSettings,
  MdOutlineLogout,
  MdQueryStats,
} from "react-icons/md";
import { Authcontext } from "./context/Authcontext";

function BusinesssidebarMobile() {
  // handle opening and closing the sidebar accordion
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  // handle naviguation between pages
  const navigate = useNavigate();


  // declare toast for notifications
  const toast = useToast();

  // handle logging out of dashboard
  const logout = async (e) => {
    // e.preventDefault();
    // try {
    //   const res = await logoutsaloon();
    //   if (res.status === 200) {
    //     navigate("/");
    //   }
    // } catch (err) {
    //   toast({
    //     title: "Server Fejl",
    //     description: "prøv venligst igen om nogle minutter",
    //     position: "top-right",
    //     isClosable: true,
    //     status: "error",
    //   });
    // }
  };

  return (
    <>
      <div className="BusinesssidebarMobile">
        <div className="Drawercontainer">
          <IconButton
            ref={btnRef}
            icon={<HamburgerIcon />}
            onClick={onOpen}
            className="Drawerbtn"
          >
            Åben
          </IconButton>
          <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>Velkommen til din konto</DrawerHeader>

              <DrawerBody>
                <div className="Nav">
                  <Link
                    to="/booking-system/dashboard"
                    className="Navitem"
                    onClick={onClose}
                  >
                    <Icon
                      as={MdSpaceDashboard}
                      className="Navicon"
                      w={5}
                      h={5}
                    />{" "}
                    Dashboard
                  </Link>
                  <Link
                    to="/booking-system/calendar"
                    className="Navitem"
                    onClick={onClose}
                  >
                    <Icon
                      as={MdCalendarToday}
                      className="Navicon"
                      w={5}
                      h={5}
                    />{" "}
                    Kalender
                  </Link>
                  <Link
                    to="/booking-system/appointments"
                    className="Navitem"
                    onClick={onClose}
                  >
                    <Icon as={MdEditCalendar} className="Navicon" w={5} h={5} />{" "}
                    Aftaler
                  </Link>
                  <Link
                    to="/booking-system/services"
                    className="Navitem"
                    onClick={onClose}
                  >
                    <Icon
                      as={MdMedicalServices}
                      className="Navicon"
                      w={5}
                      h={5}
                    />{" "}
                    Tjenester
                  </Link>
                  <Link
                    to="/booking-system/staffs"
                    className="Navitem"
                    onClick={onClose}
                  >
                    <Icon
                      as={MdPersonAddAlt1}
                      className="Navicon"
                      w={5}
                      h={5}
                    />
                    Personale
                  </Link>
                  <Link
                    to="/booking-system/reviews"
                    className="Navitem"
                    onClick={onClose}
                  >
                    <Icon
                      as={MdOutlineStarHalf}
                      className="Navicon"
                      w={5}
                      h={5}
                    />{" "}
                    Anmeldelser
                  </Link>
                  <Link
                    to="/booking-system/statistic"
                    className="Navitem"
                    onClick={onClose}
                  >
                    <Icon as={MdQueryStats} className="Navicon" w={5} h={5} />{" "}
                    Statistik
                  </Link>
                  <Link
                    to="/booking-system/payment"
                    className="Navitem"
                    onClick={onClose}
                  >
                    <Icon as={MdCreditCard} className="Navicon" w={5} h={5} />{" "}
                    Betaling
                  </Link>
                  <Link
                    to="/booking-system/upgrade"
                    className="Navitem"
                    onClick={onClose}
                  >
                    <Icon as={MdUpgrade} className="Navicon" w={5} h={5} />{" "}
                    Opgrader
                  </Link>
                  <Link
                    to="/booking-system/settings/sec/generalinfo"
                    className="SettingsNavitem"
                  >
                    <Accordion allowMultiple className="Settingsaccord">
                      <AccordionItem className="Settingsaccord">
                        <AccordionButton>
                          <Box flex="1" textAlign="left" className="Box">
                            <Icon
                              as={MdSettings}
                              className="SettingsNavicon"
                              w={5}
                              h={5}
                            />
                            <div className="Settingstxt">Indstillinger</div>
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>

                        <AccordionPanel pb={4} className="Settingslist">
                          <Link
                            className="Listitem"
                            to="/booking-system/settings/generalinfo"
                            onClick={onClose}
                          >
                            Generel information
                          </Link>
                          <Link
                            className="Listitem"
                            to="/booking-system/settings/contactinfo"
                            onClick={onClose}
                          >
                            Kontakt information
                          </Link>
                          <Link
                            className="Listitem"
                            to="/booking-system/settings/album"
                            onClick={onClose}
                          >
                            Billeder
                          </Link>
                          <Link
                            className="Listitem"
                            to="/booking-system/settings/workschedule"
                            onClick={onClose}
                          >
                            Arbejdsskema
                          </Link>
                          <Link
                            className="Listitem"
                            to="/booking-system/settings/location"
                            onClick={onClose}
                          >
                            Beliggenhed
                          </Link>
                          <Link
                            className="Listitem"
                            to="/booking-system/settings/security"
                            onClick={onClose}
                          >
                            Sikkerhed
                          </Link>
                          <Link
                            className="Listitem"
                            to="/booking-system/settings/support"
                            onClick={onClose}
                          >
                            Support
                          </Link>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </Link>
                </div>
              </DrawerBody>

              <DrawerFooter className="Drawerfooter">
                <span className="Navitem" id="Logout" onClick={logout}>
                  <Icon as={MdOutlineLogout} className="Navicon" w={5} h={5} />{" "}
                  Log ud
                </span>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </div>
        <div className="Logocontainer">
          <Link to="/booking-system/dashboard">
            <img
              src={process.env.PUBLIC_URL + "/assets/Logo/logo.png"}
              alt=""
              className="Logo"
            ></img>
          </Link>
        </div>
      </div>
    </>
  );
}

export default BusinesssidebarMobile;
